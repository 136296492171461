const kiosk_en = {
    "home": "Home",
    "functions": "Functions",
    "examples": "Examples",
    "loginUpper": "LOGIN",
    "mainSub": "The intuitive and practical touch information kiosk.",
    "mainDesc": "SkySignage's Kiosk module is a touch kiosk that allows your visitors or employees to quickly find information. Kiosk can display documents, videos, images, and websites.",
    "beContacted": "BE CONTACTED",
    "functionsTitle": "Functions",
    "functionsSub": "SkySignage Kiosk in 4 points",
    "intuitiveTitle": "Intuitive Interface",
    "intuitiveDesc": "The intuitive touch interface allows all audiences to easily access your information. Documents, actions, videos, and websites are displayed quickly.",
    "realtimeTitle": "Real-time",
    "realtimeDesc": "Your information database synchronizes in real-time with just one click.",
    "safeTitle": "Secure",
    "safeDesc": "Your information is hosted on the SkySignage infrastructure in Microsoft Azure cloud privately and securely.",
    "deployTitle": "Easy to deploy",
    "deployDesc": "There is no software to install on your employees' computers. A latest-generation browser is sufficient.",
    "townhallsTitle": "Town Halls",
    "townhallsDesc": "Improve public reception with Kiosk. Your employees can focus on value-added tasks. No more printing documents for public access. Drag and drop and synchronize touchscreen displays. In addition to documents, let the public freely access a list of preselected sites: CAFF, Town Hall, Leisure Center, School Board.",
    "companiesTitle": "Companies",
    "companiesDesc": "With Kiosk, stay up-to-date with your legal display obligations. Display all mandatory documents with drag and drop and synchronize touchscreen displays on all your sites.",
    "tourismTitle": "Tourist Offices",
    "tourismDesc": "Present all your local entertainment offers in a fun way and eliminate brochure shelves.",
    "tourismDesc2": "Display videos, posters, brochures, and websites all in one place.",
    "workscouncilsTitle": "Works Councils",
    "workscouncilsDesc": "Present your Works Council offer in a fun way and eliminate brochure shelves.",
    "workscouncilsDesc2": "Display videos, posters, brochures, and partner websites all in one place.",
    "trustTitle": "They trust us",
    "trustSub": "Some references",
    "christianDSurvey": "Kiosk is implemented in a few minutes, and agents are fully autonomous within a few hours.",
    "christianDRole": "IT Manager. Town Hall",
    "amandineMSurvey": "Kiosk allowed us to digitize our Tourist Office and achieve a paperless environment.",
    "amandineMRole": "Communication Officer. Tourist Office",
    "carolineBSurvey": "Kiosk allowed us to digitize our mandatory display on all our sites. Our documents are always up-to-date and can be updated in seconds.",
    "carolineBRole": "HR Manager. Major Account",
    "examplesTitle": "Examples",
    "examplesSub": "SkySignage Kiosk in a few images",
    "zoomcarouselTitle": "ZOOM CAROUSEL",
    "compatibleTitle": "Compatible Hardware",
    "compatibleDesc": "Kiosk works with major brands of professional connected monitors.",
    "monitorsTitle": "Touchscreen Connected Monitors",
    "monitorsDesc": "Kiosk works on touchscreen connected monitors from Samsung (Tizen), LG (WebOS), Philips, and Iiyama (Android). Click one of the buttons below to check the compatibility of your hardware.",
    "distributorsTitle": "Distributors & Integrators",
    "distributorsDesc": "Do you have a complex project and are looking for a trusted partner to deploy your touchscreen displays with SkySignage Kiosk?",
    "distributorsContact": "Contact us to be called back by an official distributor.",
    "distributeUsTitle": "Want to distribute SkySignage Kiosk?",
    "distributeUsDesc": "Are you a business distributor or IT integrator in France? Become an official distributor of SkySignage Kiosk.",
    "contactUsTitleUpper": "CONTACT US",
    "contactUsTitleLower": "Contact us",
    "contactUsDesc": "Do you have a project or a question? Do you want advice from our experts? Send us a message!",
    "lastName": "Last Name *",
    "firstName": "First Name *",
    "requiredEmail": "Email *",
    "phone": "Phone *",
    "company": "Company",
    "message": "Message *",
    "sendMessage": "Send Message",
    "ourEmail": "Our Email",
    "headquarters": "Headquarters",
    "spainOffice": "Spain Office",
    "ourPhone": "Our Phone",
    "contactUsByEmail": "Contact us by email",
    "contactOurSupport": "Contact our support",
    "legalNotice": "Legal Notice",
    "TOU": "Terms of Use",
    "privacyPolicy": "Privacy Policy"
}
