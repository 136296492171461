const kiosk_fr = {
    home: 'Accueil',
    functions: 'Fonctions',
    examples: 'Exemples',
    loginUpper: 'CONNEXION',
    mainSub: 'La borne tactile d’information intuitive et pratique.',
    mainDesc: 'Le module Kiosk de SkySignage est un kiosque tactile qui permet à vos visiteurs ou collaborateurs de trouver rapidement de l’information. Kiosk permet d’afficher documents, vidéos, images, et sites internet.',
    beContacted: 'ÊTRE CONTACTÉ',
    functionsTitle: 'Fonctions',
    functionsSub: 'SkySignage Kiosk en 4 points',
    intuitiveTitle: 'Interface intuitive',
    intuitiveDesc: 'L’interface tactile intuitive permet à tous les publics de consulter vos informations facilement. Documents, actes, vidéos, et site Internet s’affichent rapidement.',
    realtimeTitle: 'Temps réel',
    realtimeDesc: 'Votre base d’information se synchronise en temps réel en un clic.',
    safeTitle: 'Sécurisé',
    safeDesc: 'Vos informations sont hébergés sur l\'infrastructure SkySignage dans le cloud Microsoft Azure de manière privée et sécurisée.',
    deployTitle: 'Facile à déployer',
    deployDesc: 'Il n\'y a aucun logiciel à installer sur les postes de vos collaborateurs. Un navigateur de dernière génération suffit.',
    townhallsTitle: 'Mairies',
    townhallsDesc: 'Avec Kiosk améliorez l\'accueil du public. Vos collaborateurs peuvent se concentrer sur les tâches à valeur ajoutée. N\'imprimez plus les actes pour les mettre à disposition du public. Faites un glisser-déposer et synchronisez les écrans tactiles. Au-delà des documents, laissez le public consulter librement une liste de sites présélectionnés : CAFF, Mairie, Centre de loisirs, Caisse des écoles.',
    companiesTitle: 'Entreprises',
    companiesDesc: 'Avec Kiosk soyez toujours à jour de vos obligations d‘affichage légal. Affichez tous les documents obligatoires d’un glisser-déposer et synchronisez les écrans tactiles de tous vos sites.',
    tourismTitle: 'Offices de tourisme',
    tourismDesc: 'Présentez toutes vos offres locales de divertissements d’une manière ludique et supprimez les étagères de brochures.',
    tourismDesc2: 'Affichez vidéos, affiches, brochures et sites Internet en un seul point.',
    workscouncilsTitle: 'Comités d’Entreprise',
    workscouncilsDesc: 'Présentez votre offre CE d’une manière ludique et supprimez les étagères de brochures.',
    workscouncilsDesc2: 'Affichez vidéos, affiches, brochures et sites Internet partenaires en un seul endroit.',
    trustTitle: 'Ils nous font confiance',
    trustSub: 'Quelques références',
    christianDSurvey: 'Kiosk se met en œuvre en quelques minutes et les agents sont pleinement autonomes en quelques heures.',
    christianDRole: 'Responsable informatique. Mairie',
    amandineMSurvey: 'Kiosk nous a permis de digitaliser notre Office de Tourisme et d’arriver au zéro papier.',
    amandineMRole: 'Chargée communication. Office de tourisme',
    carolineBSurvey: 'Kiosk nous a permis de digitaliser notre affichage obligatoire sur tous nos sites. Nos documents sont toujours à jour et actualisables en quelques secondes.',
    carolineBRole: 'Responsable ressources humaines. Grand compte',
    examplesTitle: 'Exemples',
    examplesSub: 'SkySignage Kiosk en quelques images',
    zoomcarouselTitle: 'ZOOM CAROUSEL',
    compatibleTitle: 'Matériel compatible',
    compatibleDesc: 'Kiosk fonctionne avec les principales marques de moniteurs connectés professionnels.',
    monitorsTitle: 'Moniteurs connectés tactiles',
    monitorsDesc: 'Kiosk fonctionne sur les moniteurs connectés tactiles Samsung (Tizen), LG (WebOS), Philips et Iiyama (Android). Cliquez sur l\'un des boutons ci-dessous pour vérifier la compatibilité de votre matériel.',
    distributorsTitle: 'Distributeurs & Intégrateurs',
    distributorsDesc: 'Vous avez un projet complexe et recherchez un partenaire de confiance pour déployer vos écrans tactiles avec SkySignage Kiosk?',
    distributorsContact: 'Contactez nous pour être rappelé par un distributeur officiel',
    distributeUsTitle: 'Vous souhaitez distribuer SkySignage Kiosk?',
    distributeUsDesc: 'Vous êtes distributeur bureautique ou intégrateur informatique en France ? Devenez distributeur officiel SkySignage Kiosk',
    contactUsTitleUpper: 'CONTACTEZ NOUS',
    contactUsTitleLower: 'Contactez-nous',
    contactUsDesc: 'Avez-vous un projet, une question ? Vous souhaitez avoir un conseil de nos experts ? Envoyez-nous un message !',
    lastName: "Nom *",
    firstName: "Prénom *",
    requiredEmail: "Email *",
    phone: "Téléphone *",
    company: "Entreprise",
    message: "Message *",
    sendMessage: "Envoyer le message",
    ourEmail: "Notre Email",
    headquarters: "Siège",
    spainOffice: "Bureau Espagne",
    ourPhone: "Notre Téléphone",
    contactUsByEmail: "Contactez nous par mail",
    contactOurSupport: "Contactez notre support",
    legalNotice: "Mentions légales",
    TOU: "CGU",
    privacyPolicy: "Politique de confidentialité"
}
