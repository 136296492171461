const kiosk_es = {
    "home": "Inicio",
    "functions": "Funciones",
    "examples": "Ejemplos",
    "loginUpper": "INICIAR SESIÓN",
    "mainSub": "El quiosco táctil de información intuitiva y práctica.",
    "mainDesc": "El módulo Kiosk de SkySignage es un quiosco táctil que permite a sus visitantes o colaboradores encontrar información rápidamente. Kiosk puede mostrar documentos, videos, imágenes y sitios web.",
    "beContacted": "SER CONTACTADO",
    "functionsTitle": "Funciones",
    "functionsSub": "SkySignage Kiosk en 4 puntos",
    "intuitiveTitle": "Interfaz intuitiva",
    "intuitiveDesc": "La interfaz táctil intuitiva permite que todas las audiencias consulten su información fácilmente. Los documentos, actos, videos y sitios web se muestran rápidamente.",
    "realtimeTitle": "Tiempo real",
    "realtimeDesc": "Su base de información se sincroniza en tiempo real con un clic.",
    "safeTitle": "Seguro",
    "safeDesc": "Su información se aloja de forma privada y segura en la infraestructura SkySignage en la nube de Microsoft Azure.",
    "deployTitle": "Fácil de implementar",
    "deployDesc": "No es necesario instalar software en las estaciones de trabajo de sus colaboradores. Un navegador de última generación es suficiente.",
    "townhallsTitle": "Ayuntamientos",
    "townhallsDesc": "Con Kiosk, mejore la atención al público. Sus colaboradores pueden centrarse en tareas de mayor valor. No imprima más documentos para ponerlos a disposición del público. Arrastre y sincronice las pantallas táctiles. Además de los documentos, permita que el público consulte libremente una lista de sitios preseleccionados: CAFF, Ayuntamiento, Centro de ocio, Caja de escuelas.",
    "companiesTitle": "Empresas",
    "companiesDesc": "Con Kiosk, manténgase siempre al día con sus obligaciones de visualización legal. Muestre todos los documentos obligatorios arrastrando y sincronizando las pantallas táctiles de todas sus ubicaciones.",
    "tourismTitle": "Oficinas de turismo",
    "tourismDesc": "Presente todas sus ofertas locales de entretenimiento de manera lúdica y elimine los estantes de folletos.",
    "tourismDesc2": "Muestre videos, carteles, folletos y sitios web en un solo punto.",
    "workscouncilsTitle": "Comités de empresa",
    "workscouncilsDesc": "Presente su oferta de CE de manera lúdica y elimine los estantes de folletos.",
    "workscouncilsDesc2": "Muestre videos, carteles, folletos y sitios web de socios en un solo lugar.",
    "trustTitle": "Confían en nosotros",
    "trustSub": "Algunas referencias",
    "christianDSurvey": "Kiosk se implementa en pocos minutos y los agentes son completamente autónomos en unas pocas horas.",
    "christianDRole": "Responsable de TI. Ayuntamiento",
    "amandineMSurvey": "Kiosk nos permitió digitalizar nuestra Oficina de Turismo y lograr un entorno sin papel.",
    "amandineMRole": "Encargada de comunicación. Oficina de turismo",
    "carolineBSurvey": "Kiosk nos permitió digitalizar nuestra visualización obligatoria en todos nuestros sitios. Nuestros documentos siempre están actualizados y se pueden actualizar en segundos.",
    "carolineBRole": "Responsable de recursos humanos. Cuenta importante",
    "examplesTitle": "Ejemplos",
    "examplesSub": "SkySignage Kiosk en algunas imágenes",
    "zoomcarouselTitle": "CARRUSEL DE ZOOM",
    "compatibleTitle": "Hardware compatible",
    "compatibleDesc": "Kiosk funciona con las principales marcas de monitores táctiles conectados profesionales.",
    "monitorsTitle": "Monitores táctiles conectados",
    "monitorsDesc": "Kiosk funciona en monitores táctiles conectados Samsung (Tizen), LG (WebOS), Philips e Iiyama (Android). Haga clic en uno de los botones a continuación para verificar la compatibilidad de su hardware.",
    "distributorsTitle": "Distribuidores e integradores",
    "distributorsDesc": "¿Tiene un proyecto complejo y busca un socio de confianza para implementar sus pantallas táctiles con SkySignage Kiosk?",
    "distributorsContact": "Contáctenos para ser contactado por un distribuidor oficial",
    "distributeUsTitle": "¿Quiere distribuir SkySignage Kiosk?",
    "distributeUsDesc": "¿Es usted un distribuidor de oficina o un integrador informático en Francia? Conviértase en distribuidor oficial de SkySignage Kiosk",
    "contactUsTitleUpper": "CONTÁCTENOS",
    "contactUsTitleLower": "Contáctenos",
    "contactUsDesc": "¿Tiene un proyecto o una pregunta? ¿Desea recibir asesoramiento de nuestros expertos? ¡Envíenos un mensaje!",
    "lastName": "Apellido *",
    "firstName": "Nombre *",
    "requiredEmail": "Correo electrónico *",
    "phone": "Teléfono *",
    "company": "Empresa",
    "message": "Mensaje *",
    "sendMessage": "Enviar mensaje",
    "ourEmail": "Nuestro correo electrónico",
    "headquarters": "Sede",
    "spainOffice": "Oficina en España",
    "ourPhone": "Nuestro teléfono",
    "contactUsByEmail": "Contáctenos por correo electrónico",
    "contactOurSupport": "Contácte a nuestro soporte",
    "legalNotice": "Aviso legal",
    "TOU": "CGC",
    "privacyPolicy": "Política de privacidad"
}
